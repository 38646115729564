import { useWeb3Modal, Web3Button } from "@web3modal/react";
import { useState } from "react";
import { useAccount } from "wagmi";
import { trackGtagEvent } from "./helper";

function App() {
  const { address } = useAccount();
  const [count, setCount] = useState(1);
  const handleClick = () => {
    const num = count + 1;
    trackGtagEvent("Buy", {
      event_name: "BuyToken",
      value: "夏徐梁",
      count: count,
      address: address ?? "0x0000",
    });
    setCount(num);
  };

  return (
    <div className="App">
      <Web3Button />
      <button onClick={handleClick}>connect button</button>
      {address ?? "no account"}
    </div>
  );
}

export default App;
