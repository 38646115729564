import ReactGA from "react-ga";
import GA4 from "react-ga4";
const GA4_API_KEY = "XHjMSbHRQne2TDc6cngNCA";
export const trackGtagEvent = (name, params) => {
  if (!name || !params) {
    return;
  }

  try {
    if (GA4_API_KEY && ReactGA) {
      console.log("提交");
      GA4.gtag("event", name, {
        ...params,
        send_to: GA4_API_KEY,
      });
    }
  } catch (error) {
    console.error("trackGtagEvent", error);
  }
};
